<template>
  <div>
    <v-btn
      block
      depressed
      class="text-white font-bold"
      :color="colorBnn['color-bnn']"
      :disabled="isDisabled"
      @click="open"
    >
      <v-icon size="16" class="mr-2">
        mdi-clock-plus-outline
      </v-icon>
      ขยายเวลา
    </v-btn>

    <v-dialog v-model="dialog" persistent max-width="340">
      <v-card>
        <div class="header-modal-container">
          <div class="header-modal">
            <span class="header-modal-title">
              <div class="d-flex">
                <span class="font-bold text-black">
                  ขยายเวลาชำระเงิน
                </span>
              </div>
            </span>
          </div>
        </div>

        <div class="body-modal">
          <v-row v-bind="{ justify: 'end', 'no-gutters': true }">
            <v-col class="text-center">
              <div class="text-red font-bold">
                ขยายเวลาชำระเงินได้เพียง 1 ครั้ง
              </div>
              หากไม่ได้ทำรายการชำระเงินภายในเวลาที่กำหนด
              ระบบจะยกเลิกคำสั่งซื้อของคุณโดยอัตโนมัติ
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="consent"
                :color="colorBnn['color-bnn']"
                :disabled="isLoading"
                label="รับทราบเงื่อนไข"
                hide-details
                large
              />
            </v-col>

            <v-col cols="12" class="mt-8">
              <v-btn
                block
                depressed
                large
                color="#5bba48"
                class="text-white font-bold"
                :disabled="!consent"
                :loading="isLoading"
                @click="handleExtendTimePayAtStore"
              >
                ยืนยัน
              </v-btn>
            </v-col>

            <v-col cols="12" class="mt-4">
              <v-btn
                block
                depressed
                link
                large
                :disabled="isLoading"
                @click="close"
              >
                ยกเลิก
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import colorBnn from '@/config/color'

export default {
  name: 'ExtendTimePayAtStore',

  props: {
    title: {
      type: String,
      default: 'ยืนยันตัวตน',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {},

  computed: {
    ...mapState({
      isLoading: state => state.order.submit.isLoading,
      isSuccess: state => state.order.submit.isSuccess,
    }),
  },

  data() {
    return {
      // Import
      colorBnn,
      // ----------------

      // Data
      dialog: false,
      consent: false,
    }
  },

  methods: {
    open() {
      this.consent = false
      this.dialog = true
    },

    close() {
      this.dialog = false
    },

    async handleExtendTimePayAtStore() {
      const uid = this.$route.params.uid

      if (uid) {
        await this.$store.dispatch('order/extendTimePayAtStore', {
          uuid: uid,
        })

        this.close()

        await this.$store.dispatch('order/getOrder', uid)

        this.$forceUpdate()
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.header-modal-container
  padding: 16px 16px 0

.header-modal
  display: flex
  justify-content: center
  width: 100%

.body-modal
  padding: 16px

.header-modal-title
  font-size: 24px
  font-weight: bold
  font-family: $font-family-base !important
  align-self: center


.header-modal-close
  cursor: pointer
  color: $color-bnn-gray-light
  align-self: center

.empty-data
	text-align: center
	color: $color-bnn-gray-medium-2
	padding: 48px 0

.link
  width: fit-content
  display: inline
  color: $color-bnn
  text-decoration: underline
  &.disabled
    color: $color-bnn-gray-light
    text-decoration: none

.input-container {
	.input-otp-container {
		display: flex;
		flex-wrap: nowrap;
		gap: 12px;
		justify-content: center;
		margin-bottom: 16px;

		&.is-invalid {
			.input-otp {
				box-shadow: 0 0 0 1px $color-red;
			}
		}

		.input-otp {
			width: 100%;
			height: 68px;
			font-size: 24px;
			text-align: center;
			border: none;
			box-shadow: 0 0 0 1px $color-bnn-gray-light;
			border-radius: 4px;

			&:hover {
				box-shadow: 0 0 0 1px $color-bnn-border;
			}

			&:focus,
			&:focus-visible {
				box-shadow: 0 0 0 2px $color-bnn-border;
				outline: none;
			}

			/* Chrome, Safari, Edge, Opera */
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			&[type=number] {
				-moz-appearance: textfield;
			}
		}
	}
}

.divider-with-text {
	display: flex;
	align-items: center;
	text-align: center;
	color: $color-bnn-gray-light;
	font-size: 14px;

	&::before,
	&::after {
	  content: '';
	  flex: 1;
	  border-bottom: 1px solid #f4f4f4;
	}
	&:not(:empty)::before {
	  margin-right: 16px;
	}
	&:not(:empty)::after {
	  margin-left: 16px;
	}
}

.btn-scan-qr-code {
  font-weight: bold;
  color: $color-dark-gray-medium;

  .v-icon {
	color: $color-bnn-border
  }
}

.desc-seven-club-member {
	text-align: center;
	font-size: 14px;
	color: $color-dark-gray-medium;
}
</style>
